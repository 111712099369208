import React, { Fragment } from "react";
import { Link } from "gatsby";
import {
	Box,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Grid,
	Typography,
	withStyles
} from "@material-ui/core";
import clsx from "clsx";
import Block from "gatsby-theme-hypersite/block";

const LearnMore = ({
	faq,
	methodology,
	classes,
	className,
	children,
	...props
}) => {
	const pages = [
		{ ...faq, text: "Get answers to frequently asked questions" },
		{ ...methodology, text: "Read about our methods" }
	];

	return (
		<Block className={clsx(classes.root, className)}>
			<Box mb={8}>
				<Typography component="h2" variant="h3" align="center">
					Learn More About Our Data & Methods
				</Typography>
			</Box>
			<Grid container spacing={6}>
				{pages.map((p, i) => {
					return (
						<Fragment key={i}>
							<Grid item xs={6} key={i}>
								<Card>
									<Link to={p.frontmatter.path}>
										<CardMedia
											component="img"
											// height="140"
											image={p.frontmatter.thumb}
											alt=""
										/>
										<CardContent>
											<Typography component="h3" variant="h4">
												{p.text}
											</Typography>
										</CardContent>
									</Link>
								</Card>
							</Grid>
						</Fragment>
					);
				})}
			</Grid>
		</Block>
	);
};

export default withStyles(theme => ({
	root: {
		// "&": {
		// 	background: theme.palette.blue.med2,
		// 	color: "white",
		// },
		"& .MuiCard-root": {
			position: "relative",
			padding: theme.spacing(0, 0, "80%", 0),
			"& a": {
				position: "absolute",
				left: 0,
				top: 0,
				width: "100%",
				height: "100%"
			}
		},
		"& .MuiCardContent-root": {
			width: "100%",
			height: "100%",
			position: "absolute",
			left: 0,
			bottom: 0,
			background: theme.palette.gradient.bottom,
			display: "flex",
			alignItems: "flex-end",
			"& *": {
				color: theme.palette.text.light
			}
		}
	}
}))(LearnMore);
