import React, { useEffect, useState } from 'react';

const Datawrapper = ({id, src, title, ariaLabel}) => {
    const [dscriptAdded, setDscriptAdded] = useState(false);
    useEffect(() => {
        window.addEventListener("message", (function(a) {
            if (void 0!==a.data["datawrapper-height"]) {
                var e=document.querySelectorAll("iframe");
                for(var t in a.data["datawrapper-height"]) {
                    for(var r=0;r<e.length;r++) {
                        if(e[r].contentWindow===a.source) {
                            var i=a.data["datawrapper-height"][t]+"px";
                            e[r].style.height=i
                        }
                    }
                }
            }
        }))
    }, []);    
    return (
        <div className="data-wrapper-container" style={{marginTop: '1rem', marginBottom: '2rem'}}>
            {/* <h2>Datawrapper:</h2> */}
            <iframe title={title} aria-label={ariaLabel} id={id} src={src} scrolling="no" frameBorder="0" style={{width: 0, minWidth: '100% !important', width: '100%', border: 'none'}} height="342" data-external="1"></iframe>           
        </div>        
    );

}

export default Datawrapper;
