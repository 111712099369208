import React, { useEffect, useState } from 'react';

const Tableau = ({width, height, id, name, staticImg, device="desktop"}) => {
    const [tscriptAdded, setTscriptAdded] = useState(false);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
        script.async = true;
        document.body.appendChild(script);
        setTscriptAdded(true);
        return () => {
            document.body.removeChild(script);
        };
    }, []);    
    return (
        <div className="tableau-container">
            {/* <h2>Tableau:</h2> */}
            <object className='tableauViz' style={{width, height}}>
                <param name='host_url' value='https%3A%2F%2Fpublic.tableau.com%2F' /> 
                <param name='embed_code_version' value='3' /> 
                <param name='site_root' value='' />
                <param name='name' value={name} />
                <param name='tabs' value='no' />
                <param name='toolbar' value='yes' />
                <param name='static_image' value={staticImg} /> 
                <param name='animate_transition' value='yes' />
                <param name='display_static_image' value='yes' />
                <param name='display_spinner' value='yes' />
                <param name='display_overlay' value='yes' />
                <param name='display_count' value='yes' />
                <param name='language' value='en-US' />
                <param name='filter' value='publish=yes' />
                <param name='device' value={device} />
            </object>
        </div>        
    );

}

export default Tableau;