import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "gatsby-theme-hypersite/src/layout";
import Block from "gatsby-theme-hypersite/block";
import { Container } from "@hyperobjekt/material-ui-website";
import { Box, Grid, Typography, withStyles } from "@material-ui/core";
import {
	Chart,
	DataContext,
	AboutTheData,
	DownloadData,
	JumpLinks,
	JumpTarget,
	JurisdictionHero,
	LearnMore,
	Map,
	MoreStats,
	PolicySummary,
	StateTable,
	Stats,
	Table,
	getLevel
} from "../components";
import { formatDate, roundNumber } from "../utils";
import { hasPolicySummary } from "../components/blocks/policy-summary/schema";
import { COLORS } from "../theme";

const StateLayout = ({
	classes,
	className,
	children,
	hero,
	data,
	...props
}) => {
	const [latestMonth, setLatestMonth] = useState(null);
	const [combinedWeekly, setCombinedWeekly] = useState([]);
	const [combinedMonthly, setCombinedMonthly] = useState([]);
	const [showServiceAreas, setShowServiceAreas] = useState(false);

	const national = data.national;
	const state = data.states;
	const counties = data.allCounties.nodes;
	const monthly = data.monthly.nodes;
	const weekly = data.weekly.nodes;
	const faq = data.faq;
	const methodology = data.methodology;
	const { root, container, content, imageWrapper, image } = classes;

	// we only want to show certain blocks when we are tracking all counties in a state
	// we also do not want to show certain blocks when the focus is on the US Virgin Islands (because demographic data is only available at the territory-level so we cannot show municipal-level data)
	const statewide_data =
		parseInt(state.counties_tracked) == parseInt(state.counties_total)
			? true
			: false;

	let sections = [
		{ slug: "about", name: `About this ${getLevel(data.states.fips)}` },
		{ slug: "evictions", name: "Eviction filings and defaults" },
		{ slug: "county", name: "Eviction filings by jurisdiction" },
		{ slug: "policy", name: "Policy summary" },
		// { slug: "data", name: "Download the data" },
		{ slug: "learn", name: "FAQs & Methodology" }
	].filter(s => (!hasPolicySummary(state) ? s.slug !== "policy" : true));

	sections = statewide_data
		? sections
		: sections.filter(function (obj) {
				return obj.slug !== "evictions";
		  });
	return (
		<Layout
			{...props}
			sections={sections}
			HeroComponent={
				<JurisdictionHero
					classes={{ root, container, content, imageWrapper, image }}
					image={
						<Map
							albers={true}
							level="nation"
							stateName={state.name}
							stateFips={state.fips}
							stateShort={state.short}
							bbox={national.bbox}
							isInHero={true}
						/>
					}
					color="text.primary"
					alt=""
				>
					<Typography component="div" variant="subtitle1">
						{hero.title}
					</Typography>
					<Typography
						component="h1"
						variant="h2"
						className={classes.title}
						gutterBottom
					>
						{state.name}
					</Typography>
					<Typography component="div" variant="subtitle1">
						{state.counties_tracked} of {state.counties_total}{" "}
						counties/municipalities tracked
					</Typography>

					{/* we only want to show the stats when we have statewide data, otherwise the user might misinterpret the information as for the whole state when it is actually only for a small number of counties */}

					{statewide_data ? (
						<Stats
							data={state}
							// commenting out the national param removes the national comparisons
							// national={national}
						/>
					) : (
						<></>
					)}
				</JurisdictionHero>
			}
		>
			<JumpTarget id="about" />

			<MoreStats
				level={getLevel(data.states.fips)}
				data={state}
				national={national}
			/>

			<JumpTarget id="evictions" />

			{/* we only want to show the charts when we have statewide data */}
			{statewide_data ? (
				<>
					<DataContext
						name={state.name}
						moratoria={state.moratorium_dates}
						context={state.text_context}
					/>

					<Chart
						level="state"
						name={state.name}
						data={{
							monthly,
							weekly
						}}
					/>
				</>
			) : (
				<></>
			)}

			{(counties && counties.length) || (true && state.fips != 78) ? (
				<>
					<JumpTarget id="county" />

					<Box className={classes.explorer}>
						<Grid container spacing={0}>
							<Grid item xs={12} md={6}>
								<Container>
									<Typography component="h2" variant="h4" gutterBottom>
										Eviction Filings by Jurisdiction
									</Typography>

									<Typography paragraph>
										Hover over counties in the map or table to highlight. Click
										counties to view detailed data.
									</Typography>

									<Table
										className={classes.table}
										data={counties}
										defaultOrderBy="filings_latest"
										defaultDirection="desc"
										selectBy="name"
										schema={[
											{
												key: "name",
												label: "County",
												link: true
											},
											{
												key: "filings_count",
												label: "Filings",
												tooltip: "Since March 2020"
											},
											{
												key: "filings_rate",
												label: "Filings rate",
												percent: true,
												tooltip: "Per 100 renter households from {{dates}}"
											},
											{
												key: "filings_latest",
												label: "Latest month's filings",
												tooltip: "Filings in {{date}}"
											},
											{
												key: "poverty_rate",
												label: "Poverty rate",
												percent: true
											}
										]}
										palette={{
											background: COLORS.paperSecondary,
											text: COLORS.light
										}}
									/>
								</Container>
							</Grid>
							<Grid item xs={12} md={6}>
								<Container>
									<Map
										level="state"
										interactive="counties"
										stateName={state.name}
										stateFips={state.fips}
										stateShort={state.short}
										bbox={state.bbox}
										latestMonth={latestMonth}
										showKeyToggle={true}
										showOverlayToggle={true}
										showLegend={true}
										showMarker={true}
										padding={50}
										data={{
											states: [state],
											counties: counties
										}}
									/>
								</Container>
							</Grid>
						</Grid>
					</Box>
				</>
			) : (
				""
			)}

			<JumpTarget id="policy" />

			<PolicySummary data={state} />

			<JumpTarget id="data" />

			<AboutTheData data={state} />
			{/* <DownloadData data={state} /> */}

			<JumpTarget id="learn" />

			<LearnMore faq={faq} methodology={methodology} />
		</Layout>
	);
};

StateLayout.defaultProps = {
	hero: {
		title: "Trends in eviction filings:"
		// lede: "The Legal Services Corporation (LSC) Eviction Tracker provides access to multi-year trend data on eviction filings for 1,250 counties and municipalities in 30 states and territories across the United States.",
		// image: "/images/hero-state-pa.png",
	}
};

StateLayout.propTypes = {};

export default withStyles(theme => ({
	title: {
		paddingTop: theme.spacing(2)
	},
	paragraph: {
		maxWidth: 438,
		[theme.breakpoints.up("md")]: {
			marginLeft: theme.spacing(0)
		},
		[theme.breakpoints.up("lg")]: {
			marginLeft: theme.spacing(0)
		}
	},
	explorer: {
		"& > .MuiGrid-root > .MuiGrid-item": {
			padding: theme.spacing(6, 0),
			"&:first-child": {
				backgroundColor: theme.palette.background.paperSecondary
			},
			"&:last-child": {
				position: "relative"
			}
		}
	},
	table: {
		"& > div > div": {
			margin: "0 !important"
		}
	}
}))(StateLayout);

export const query = graphql`
	query ($fips: Int) {
		national {
			filings_count
			filings_latest
			filings_latest_date
			rent_burdened
			poverty_rate
			bbox
		}
		states(fips: { eq: $fips }) {
			name
			fips
			short
			counties_total
			counties_tracked
			filings_count
			filings_rate
			filings_rate_dates
			filings_latest
			filings_latest_date
			represent_tenants
			represent_landlords
			disposed_case_count
			cases_default
			cases_judgment
			rent_burdened
			poverty_rate
			moratorium_dates
			text_context
			text_about
			withhold_rent
			notice_req_nonpay
			notice_min_nonpay
			notice_req_other
			notice_min_other
			edp_require
			notice_ls
			filing_min_nonpay
			first_court
			filing_fee
			just_cause
			summons_respond
			summons_min
			summons_ls
			pay_after_filing
			appeal_allowed
			appeal_min
			appeal_bond
			writ_issue_min
			writ_exec_min
			pay_after_judg
			seal_cases
			reg_disclosure
			withhold_rent_info
			notice_req_nonpay_info
			notice_min_nonpay_info
			notice_req_other_info
			notice_min_other_info
			edp_require_info
			notice_ls_info
			filing_min_nonpay_info
			first_court_info
			filing_fee_info
			just_cause_info
			summons_respond_info
			summons_min_info
			summons_ls_info
			pay_after_filing_info
			appeal_allowed_info
			appeal_min_info
			appeal_bond_info
			writ_issue_min_info
			writ_exec_min_info
			pay_after_judg_info
			seal_cases_info
			reg_disclosure_info
			bbox
		}
		allCounties(filter: { state_fips: { eq: $fips } }) {
			nodes {
				id
				bbox
				fips
				fips_if_multi_county
				name
				state_name
				state_fips
				state_short
				filings_rate
				filings_rate_dates
				filings_count
				filings_latest
				filings_latest_date
				poverty_rate
			}
		}
		monthly: allMonthly(filter: { fips: { eq: $fips } }) {
			nodes {
				id
				fips
				name
				state_fips
				date
				filings_count
				percent_of_historical_average
				judgments_count
				defaults_count
				defaults_rate
			}
		}
		weekly: allWeekly(filter: { fips: { eq: $fips } }) {
			nodes {
				id
				fips
				name
				state_fips
				date
				filings_count
			}
		}
		faq: mdx(slug: { eq: "faq" }) {
			frontmatter {
				thumb
				path
			}
		}
		methodology: mdx(slug: { eq: "methodology" }) {
			frontmatter {
				thumb
				path
			}
		}
	}
`;
