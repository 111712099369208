import React from "react";
import { Link } from "gatsby";
import { Grid, Typography, Button, withStyles } from "@material-ui/core";
import clsx from "clsx";
import Block from "gatsby-theme-hypersite/block";

const DownloadData = ({ data, classes, className, children, ...props }) => {
	return (
		<Block className={clsx(classes.root, className)}>
			<Typography component="h2" variant="h3" gutterBottom>
				Download the Data
			</Typography>
			<Grid container spacing={6}>
				<Grid item xs={12} md={6}>
					<Typography paragraph>
						Please cite the data as: Civil Court Data Initiative. Legal Services
						Corporation, 2022. (accessed TODAY’s DATE).
					</Typography>

					{/*
          the functionality to download just the specific state or county was not implemented, so we remove this paragraph.

          <Typography
            paragraph>
            Use the buttons to download filing counts, demographic, and definitional information for each site. Filing count files are available either for the specific county displayed on this page or all available counties in the selected state.
          </Typography>
        */}

					<Typography>
						Eviction filings vary significantly across counties and states due to differences in local laws and court processes. Any analysis of eviction filings must take these differences into account in order to make accurate comparisons across specific localities.
					</Typography>
				</Grid>

				<Grid item xs={12} md={6}>
					&nbsp;
					<Grid container spacing={3}>
						{[
							// {
							// 	label: "Monthly Data",
							// 	url: "/downloads/monthly.csv",
							// },
							// {
							// 	label: "Weekly Data",
							// 	url: "/downloads/weekly.csv"
							// },							
							{
								label: "Monthly County Data",
								url: "/downloads/monthly_county_data_download.csv"
							},
							{
								label: "Monthly State Data",
								url: "/downloads/monthly_state_data_download.csv"
							},
							{
								label: "Weekly County Data",
								url: "/downloads/weekly_county_data_download.csv"
							},
							{
								label: "Weekly State Data",
								url: "/downloads/weekly_state_data_download.csv"
							}							
							//{
							//  label: "Demographic data"
							//},
							//{
							//  label: "Definitional data"
							//}
						].map((b, i) => (
							<Grid item xs={12} lg={6} key={i}>
								<Button
									value={b.label}
									variant="contained"
									href={b.url}
									download
								>
									{b.label}
								</Button>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		</Block>
	);
};

export default withStyles(theme => ({
	root: {
		padding: theme.spacing(15, 0),
		backgroundColor: theme.palette.blue.med2, //theme.palette.blue.dark
		"& *": {
			color: theme.palette.text.light
		},
		"& .MuiButton-root": {
			width: "100%",
			backgroundColor: theme.palette.blue.main,
			textAlign: "center",
			"&:hover": {
				backgroundColor: theme.palette.red.main
			}
		}
	}
}))(DownloadData);
